(function() {

	$('.go-back').click(function(e) {
		e.preventDefault();
		window.history.back();
	});

	$('.need-login').click(function(e) {
		e.preventDefault();
		swal({ title: '',
			text: 'Please login to continue.',
			type: 'info',
			confirmButtonText: "OK",
			closeOnConfirm: false,
			showCancelButton: false
		});
	});

	var bodyEl = document.body,
		content = document.querySelector( '.content-wrap' ),
		openbtn = document.getElementById( 'open-button' ),
		closebtn = document.getElementById( 'close-button' ),
		isOpen = false;

	function init() {
		initEvents();
	}

	function initEvents() {
		openbtn.addEventListener( 'click', toggleMenu );
		if( closebtn ) {
			closebtn.addEventListener( 'click', toggleMenu );
		}

		// close the menu element if the target it´s not the menu element or one of its descendants..
		content.addEventListener( 'click', function(ev) {
			var target = ev.target;
			if( isOpen && target !== openbtn ) {
				toggleMenu();
			}
		} );
	}

	function toggleMenu() {
		if( isOpen ) {
			classie.remove( bodyEl, 'show-menu' );
		}
		else {
			classie.add( bodyEl, 'show-menu' );
		}
		isOpen = !isOpen;
	}

	init();

	$('#loginButton').click(function() {
		toggleMenu();
	});  

	$(".select2").select2();

	function checkLoginState() {
		FB.getLoginStatus(function(response) {
			statusChangeCallback(response);
		});
	}

	function statusChangeCallback(response) {
		console.log(response);
	}

	function submitForm(url, data, type, form) {
		form.find('button').html(`<i class="fal fa-spinner fa-spin"></i>`);
		form.find('button').attr('disabled','disabled');
		$.ajax({
			type : type,
			url: url,
			headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
			data : data,
			dataType : 'json',
			processData: false,
			success : function(data) {
				var title = data.notifTitle;
				var message = (_.isUndefined(data.notifMessage) ? '' : data.notifMessage);
				swal({title: "",
					text: '',
					type: 'success',
					confirmButtonText: "OK",
					closeOnConfirm: false,
					showCancelButton: false
				});
				if (data.resetForm) {
					resetForm(form);
				}
	
				if (! _.isUndefined(data.redirect)) {
					setTimeout(function() {
						window.location = data.redirect;
					}, 3000);
				}
	
				if (! _.isUndefined(data.links)) {
					var links = JSON.parse(data.links);
					for (var i = links.length - 1; i >= 0; i--) {
						console.log(links[i]);
						window.open(links[i], '_blank');
					}
				}
	
				form.find('button').removeAttr('disabled');
				form.find('button').html(`SUBMIT`);
			},
			error : function(data, text, error) {
				var message = '';
				_.each(data.responseJSON, function(val) {
					message += val + ' ';
				});
				swal({title: "",
					text: 'Email sending failed.',
					type: 'error',
					confirmButtonText: "OK",
					closeOnConfirm: false,
					showCancelButton: false
				});
				
				form.find('button').removeAttr('disabled');
				form.find('button').html(`SUBMIT`);
			}
		});
	};

	function resetForm(form) {
		form.find('input[type!="hidden"]').val('');
		form.find('textarea').val('');
	};

	var loginForm = $('form.login-form');
    var requiredFields = ['email', 'password'];

    loginForm.submit(function(e){
        e.preventDefault();
		var isValid = true;

		if (isValid) {
			var url = loginForm.attr('action');
			var data = loginForm.serialize();
			var method = loginForm.find('input[name="_method"]').val();
			var type = (_.isUndefined(method) ? 'POST' : method);
			var form = $('form.login-form');
			$.ajax({
                type : type,
                url: url,
                headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
                data : data,
                processData: false,
                success : function(response) {
					if(response.data) {
						swal({title: 'Hi, ' + response.data.first_name + '!',
							text: '',
							type: 'success',
							confirmButtonText: "OK",
							closeOnConfirm: false,
							showCancelButton: false
						}, function(okay) {
							window.location.reload();
						});
					} else {
						swal({title: "",
							text: response.message,
							type: 'error',
							confirmButtonText: "OK",
							closeOnConfirm: false,
							showCancelButton: false
						});
					}
                   
                       
                    if (data.resetForm) {
                        resetForm(form);
                    }
        
                    if (! _.isUndefined(data.redirect)) {
                        setTimeout(function() {
                            window.location = data.redirect;
                        }, 3000);
                    }
        
                    if (! _.isUndefined(data.links)) {
                        var links = JSON.parse(data.links);
                        for (var i = links.length - 1; i >= 0; i--) {
                            console.log(links[i]);
                            window.open(links[i], '_blank');
                        }
                    }
        
                    form.find('button').removeAttr('disabled');
                    form.find('button').html(`SUBMIT`);
                },
                error : function(data, text, error) {
                    var message = '';
                    _.each(data.responseJSON, function(val) {
                        message += val + ' ';
					});
                    swal({title: "",
                        text: 'Email sending failed.',
                        type: 'error',
                        confirmButtonText: "OK",
                        closeOnConfirm: false,
                        showCancelButton: false
                    });
                    
                    form.find('button').removeAttr('disabled');
                    form.find('button').html(`SUBMIT`);
                }
            });
		}
	});

	$(document).ready(function() {
		//===== REDACTOR
		var redactor = $('.redactor');
		if (redactor.length > 0) {
			var redactorUpload = redactor.data('redactor-upload');
			var token = redactor.closest('form').find('input[name="_token"]').val();
			redactor.redactor({
				minHeight: '200px',
				imageUpload: redactorUpload + '?_token=' + token,
				imageResizable: true,
				plugins: ['fontcolor', 'fontsize', 'alignment', 'counter']
			});
		}

		$("[reset-on-show]").on('hidden.bs.modal', function (e) {
			clearInputs(serialzeArrayToObject($("form", this).serializeArray()));
		});
		
		$('select.select2-allow-creation').select2({
			tags: true
		});
		
		$.each($('.form-parsley'), function() {
			$(this)
				.parsley()
					.on('field:validated', function() {
						var ok = $('.parsley-error').length === 0;
						$('.bs-callout-info').toggleClass('hidden', !ok);
						$('.bs-callout-warning').toggleClass('hidden', ok);
					})
					.on('form:validated', function() {
						
					})
					.on('form:submit', function() {
						var _this = $($($(this)[0]["$element"][0])[0]);
						var ok = $('.parsley-error').length === 0;
						$('.bs-callout-info').toggleClass('hidden', !ok);
						$('.bs-callout-warning').toggleClass('hidden', ok);

						if (ok) {
							if (_this.attr("parsleysubmitaction")) {
								eval(`${_this.attr("parsleysubmitaction")}("${_this.attr("actionroute")}", "${_this.attr("actiontype")}", "${_this.attr("id")}")`);
							}
						}
						
						return false; // Don't submit form for this demo
					});
		});

		// $('#parsley-form').parsley();

		var parselyForm = $('#parsley-form');
		if (parselyForm.length > 0) {
			$.each(parselyForm, function () {
				var pForm = $(this);
				pForm.submit(function (e) {
					e.preventDefault();
				});
				var url = pForm.attr('action');
				var type = pForm.attr('method');
				pForm.parsley().on('form:submit', function () {
					var form = pForm.serialize();
					var data = new FormData(form);
					
					$("#send").prop( "disabled", true );
					$("#send").html('Sending...');
					
					submitForm(url, data, type);
					return false;
				});
			});
		}
		
		// $('#button').on('click', function() { 
		// 	// The button is disabled but this will be executed. 
		// 	alert( 'disabled ');
		// });

		var item_length = $('.intro-slider > div').length - 1;

		setTimeout(function () { $(".intro-slider").slick({
					infinite: false,
					dots: true,
					arrows: true,
					prevArrow: '.prev-btn',
					nextArrow: '.next-btn'
				}); 
				$(".intro-slider").addClass("slick-loaded");
				$("#loadDisplay").removeClass("load-display");}, 2000);

		$(".intro-slider").on('afterChange', function(event, slick, currentSlide, nextSlide){
			var currentSlide = $('.intro-slider').slick('slickCurrentSlide');
			if (item_length == currentSlide){
				if($("button.next-btn").hasClass("slick-disabled")){
					$("button.close-btn").addClass("last-slide");
				}
			}
			else{
				$("button.close-btn").removeClass("last-slide");
			}
		});

		$("#help-click").on('click', function(){
			$(".intro-slider").removeClass("slick-loaded");
			$("#loadDisplay").addClass("load-display");
			setTimeout(function () { $(".intro-slider").slick('refresh');}, 1500);
			setTimeout(function () { 
				$(".intro-slider").addClass("slick-loaded");
				$("#loadDisplay").removeClass("load-display");}, 1500);
			$('#introHelpModal').modal('show');
			setTimeout(function(){
				$(".intro-slider").addClass("slick-loaded");
			}, 1500);

		});

		$("#contact-click").on('click', function(){
			$('#contactModal').modal('show');
		});

		$('#introModal').modal('show');

	});

	function sendInquiry(endpoint, category, formId) {
		var inputs = serialzeArrayToObject($("#" + formId).serializeArray());
		inputs.category = category;
		appendJumpingLoader("body", true);

		$.ajax({
			type: "POST",
			url: endpoint,
			data: inputs,
			success: function(res) {
				console.log(res);
				removeJumpingLoader();
				clearInputs(formId, inputs);
				$(".modal").modal("hide");

				swal({
					type: "success",
					title: "Success",
					text: "Your inquiry was successfully submitted."
				});
			}
		});
	}

	function clearInputs (formId, inputs) {
		$.each(inputs, function(index) {
			$("#" + formId + " [name='" + index + "']").val("");
		});
	}

	function serialzeArrayToObject (serializedArray) {
		var obj = {};

		$.each(serializedArray, function(iindex, serializedObj) {
			obj[serializedObj.name] = serializedObj.value;
		});

		return obj;
	}

	function appendJumpingLoader (target, fullpage) {
		if (target === undefined) {
			target = $("body");
		} else {
			target = $(target);
		}

		target.append(`<div class="jumping-loader ${fullpage ? "full-page" : ""}">
							<div class="jumping-circles">
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>`);
	}

	function removeJumpingLoader () {
		$(".jumping-loader").remove();
	}

})();